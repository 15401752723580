import md5 from 'md5'
import React from 'react'

import { API_KEY, API_PASSWORD, LOGIN_URL, platonDataForPayment } from 'config'
import { IPaymentInfo } from 'redux/reducers/client/allState/types/stateTypes'
import { translateType } from 'translations/types'

export const { PAYMENT, CURRENCY, DESCRIPTION, REQ_TOKEN } = platonDataForPayment

export const getValidation = (t: translateType) => {
  return {
    required: t('formValidation.required')
  }
}

export const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  const string = event.currentTarget.value
  event.currentTarget.value = string.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
  if (string[0] === '.' || string[0] === '0') event.currentTarget.value = ''
}
export const transformDataToValidFormat = (amount: string) => {
  const amountField = amount.split('.')

  if (amountField[1] === undefined) {
    amountField[1] = '.00'
  } else if (amountField[1].length === 1) {
    amountField[1] = `.${amountField[1] + '0'}`
  } else if (amountField[1].length === 2) {
    return amount
  } else if (amountField[1].length > 2) {
    amountField[1] = `.${amountField[1].slice(0, 2)}`
  }

  return amountField.join('')
}

const strRev = (string: string): string => {
  return string.split('').reverse().join('')
}

// params: client_key, payment, amount, currency, description, url, client_pass
export const getMd5Hash = (
  API_KEY: string,
  PAYMENT: string,
  amount: string,
  CURRENCY: string,
  DESCRIPTION: string,
  URL: string,
  API_PASSWORD: string
) => {
  return md5((strRev(API_KEY) + strRev(PAYMENT) + strRev(amount) + strRev(CURRENCY) +
    strRev(DESCRIPTION) + strRev(URL) + strRev(API_PASSWORD)).toUpperCase())
}

export interface IRequestData {
  key: string
  payment: string
  amount: string
  currency: string
  description: string
  req_token: string
  url: string
  sign: string

  order: string
  email: string
  phone: number
  first_name: string
  last_name: string
}

export const getRequestData = (amount: string, paymentInfo: IPaymentInfo): IRequestData => {
  // params: client_key, payment, currency, description, url, client_pass
  const hash = getMd5Hash(API_KEY!, PAYMENT, amount, CURRENCY, DESCRIPTION, LOGIN_URL, API_PASSWORD!)

  return {
    key: API_KEY!,
    payment: PAYMENT,
    amount,
    currency: CURRENCY,
    description: DESCRIPTION,
    req_token: REQ_TOKEN,
    url: LOGIN_URL,
    sign: hash,

    order: paymentInfo.order,
    email: paymentInfo.email,
    phone: +paymentInfo.phone,
    first_name: paymentInfo.first_name,
    last_name: paymentInfo.last_name
  }
}
