import { translateType } from 'translations/types'

interface IValidation {
  applicationNumber: {
    required: string;
    min: {
      value: number;
      message: string;
    };
  };
  email: {
    required: string;
    pattern: {
      value: RegExp;
      message: string;
    };
  };
  amount: {
    required: string;
    min: {
      value: number;
      message: string;
    };
  };
}

export const getGuestPaymentValidation = (t: translateType): IValidation => {
  return {
    applicationNumber: {
      required: t('formValidation.required'),
      min: {
        value: 1,
        message: t('formValidation.min', { n: 1 })
      }
    },
    email: {
      required: t('formValidation.required'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('formValidation.pattern')
      }
    },
    amount: {
      required: t('formValidation.required'),
      min: {
        value: 1,
        message: t('formValidation.min', { n: 1 })
      }
    }
  }
}
