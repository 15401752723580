import { ThunkAction } from 'redux-thunk'

import { authApi } from 'api/authApi'
import { clientApi } from 'api/clientApi'
import { ISignUpData } from 'components/pageHome/signUpEmailPage/SignUpForm/SignUpForm'
import { CLIENT_URL } from 'config'
import { TState } from 'redux/store'
import { delete_cookie, getCookie } from 'utils/common/apiHelpers'

import { IUserFlowData } from '../lender/allState/types/state'

import {
  handleLoginError,
  logout,
  setAppFormUserFlow,
  setAuthErrors,
  setIsFetching,
  setSettings,
  setUser,
  setUserFlowLoadStatus,
  updateAppFormUserFlow
} from './authReducer'
import { authActionTypes } from './types/actionTypes'
import { loginData } from './types/stateTypes'

type authThunk = ThunkAction<void, TState, unknown, authActionTypes>

export const loginThunk =
  (data: loginData): authThunk =>
    async dispatch => {
      dispatch(setIsFetching(true))
      const res = await authApi.login(data)

      if (res.status === 200) {
        const expiration = new Date(res.data.expiry)

        delete_cookie('token')
        document.cookie = `token=${res.data.token}; expires=${expiration.toUTCString()}; path=/`

        dispatch(setUserThunk())
        dispatch(handleLoginError(null))
      } else {
        dispatch(handleLoginError({ status: res.status, text: res.data.non_field_errors[0] }))
      }
      dispatch(setIsFetching(false))
    }

export const googleSignInThunk =
  (token: string): authThunk =>
    async dispatch => {
      dispatch(setIsFetching(true))
      const res: any = await authApi.googleSignIn(token)

      if (res.status === 200) {
        const expiration = new Date(res.data.expiry)

        delete_cookie('token')
        document.cookie = `token=${res.data.token}; expires=${expiration.toUTCString()}; path=/`

        dispatch(setUserThunk())
        dispatch(handleLoginError(null))
      } else {
        dispatch(handleLoginError({ status: res.status, text: res.data.details }))
      }
      dispatch(setIsFetching(false))
    }

export const facebookSignInThunk =
  (token: string): authThunk =>
    async dispatch => {
      dispatch(setIsFetching(true))
      const res: any = await authApi.facebookSignIn(token)

      if (res.status === 200) {
        const expiration = new Date(res.data.expiry)

        delete_cookie('token')
        document.cookie = `token=${res.data.token}; expires=${expiration.toUTCString()}; path=/`

        dispatch(setUserThunk())
        dispatch(handleLoginError(null))
      } else {
        dispatch(handleLoginError({ status: res.status, text: res.data.details }))
      }
      dispatch(setIsFetching(false))
    }

export const setUserThunk = (): authThunk => async dispatch => {
  const token = getCookie('token')
  if (!token) return

  try {
    const user = await authApi.setUser(token)

    if (user.status === 200) {
      const {
        user_flow,
        email,
        groups,
        role,
        permissions,
        id,
        is_social,
        first_name,
        last_name,
        bonus_count
      } = user.data

      if (user_flow) dispatch(setAppFormUserFlow(user_flow))

      dispatch(
        setUser({
          email,
          group: groups[0].name,
          role,
          permissions,
          id,
          is_social,
          user_flow,
          first_name,
          last_name,
          bonus_count
        })
      )
      if (user_flow === null && window.location.href.includes('client/application')) {
        window.location.href = `${CLIENT_URL}/client/active-loans`
      }
    }
  } catch (error: any) {
    dispatch(logout())
    delete_cookie('token')
  }
}

export const logoutThunk = (): authThunk => async dispatch => {
  dispatch(setIsFetching(true))

  try {
    const res = await authApi.logout(getCookie('token'))

    if (res.status === 204) {
      dispatch(setAppFormUserFlow(null))
      dispatch(logout())
      delete_cookie('token')
      localStorage.clear()
    }
  } catch (error: any) {
    console.log(`error ${error.message} in logoutThunk`)
  } finally {
    dispatch(setIsFetching(false))
  }
}

export const signUpClientThunk =
  (data: ISignUpData): authThunk =>
    async dispatch => {
      const res = await authApi.signUpClient(data)

      if (res.status === 201) {
        await authApi.sendActivationSMS(res.data.user_id)
        dispatch(setAuthErrors([]))
        return res.data
      }
      if (res.status === 400) {
        const errors = []
        for (const item in res.data) {
          errors.push(res.data[item])
        }
        dispatch(setAuthErrors(errors))
      }
      return false
    }

export const sendActivationEmailThunk =
  (email: string): authThunk =>
    async dispatch => {
      const res = await authApi.sendActivationEmail(email)
    }

export const checkActivationSMSThunk = (userId: string | number, data: { sms_code?: string, phone_number?: string }): authThunk => async dispatch => {
  const res = await authApi.checkActivationSMS(userId, data)
  if ([201, 200].includes(res.status)) {
    return true
  }

  if ([400, 403].includes(res.status)) {
    dispatch(setAuthErrors([res.data.message]))
  }
  return false
}

export const getSettingsThunk = (): authThunk => async dispatch => {
  const res = await authApi.settings.getSettings()

  if (res.status === 200) {
    dispatch(setSettings(res.data))
  }
}
export const updateSettingsThunk =
  (status: boolean): authThunk =>
    async dispatch => {
      const res = await authApi.settings.patchSettings(status)

      if (res.status === 200) {
        dispatch(setSettings([res.data]))
      }
    }

export const updateCardStepSettingsThunk =
  (id: number, status: boolean): authThunk =>
    async dispatch => {
      const res = await authApi.settings.patchCardStepSettings(id, status)

      if (res.status === 200) {
        dispatch(setSettings([res.data]))
      }
    }

// IUserFlowData
export const updateAppFormUserFlowThunk =
  (data: Partial<IUserFlowData>): authThunk =>
    async (dispatch, getState) => {
      dispatch(setUserFlowLoadStatus('load'))
      const appFormFlowData = getState().auth.userFlow.appFormFlowData

      if (!appFormFlowData) return
      try {
        const { id, flow_data } = appFormFlowData
        const res = await clientApi.applications.patchUserFlowData(id, { ...flow_data, ...data })

        if (res.status === 200) {
          dispatch(updateAppFormUserFlow(res.data.flow_data))
          dispatch(setUserFlowLoadStatus('success'))
          return true
        }
      } catch (err: any) {
        if (err.response.status === 404) {
          window.location.href = `${CLIENT_URL}/client/active-loans`
        }
        dispatch(setUserFlowLoadStatus('error'))
        return false
      }
    }
export const deleteAppFormUserFlowThunk =
  (id: number): authThunk =>
    async dispatch => {
      dispatch(setUserFlowLoadStatus('load'))
      try {
        const res = await clientApi.applications.deleteUserFlowData(id)

        if (res.status === 204) {
          dispatch(setAppFormUserFlow(null))
          dispatch(setUserFlowLoadStatus('success'))
          return true
        }
      } catch (err: any) {
        dispatch(setUserFlowLoadStatus('error'))
        return false
      }
    }
