import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { fetchBankIdApplicationId } from 'components/api/fetchBankIdApplicationId'
import { useSearchParams } from 'components/common/hooks/useSearchParams'
import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'

const BankIdCallbackPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { appFormFlowData } = useSelector(userFlowSelector)

  const urlParams = useSearchParams()

  const code = urlParams('code')
  const stateUUID = urlParams('state')

  useEffect(() => {
    if (!code || !stateUUID) {
      history.push('/')
    }

    fetchBankIdApplicationId(code, stateUUID).then((r) => {
      if (r.error) {
        dispatch(setClientToastMessage({
          message: r.error,
          type: 'error'
        }))

        history.push('/')
      }

      if (r.appId) {
        // toast param syntax `message:type`
        if (r.type === 'guarantor') {
          history.push('/client/loan-guarantee?toast=Success:success')
          localStorage.removeItem('guarantor_choose_application_id')
        } else {
          if (appFormFlowData) {
            dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step + 1 }))
          }
          history.push('/client/application/?toast=Success:success')
        }
      }
    })
  }, [code, dispatch, history, stateUUID])

  return (
    <div>
    </div>
  )
}

export default BankIdCallbackPage
