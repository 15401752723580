import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { connect, useDispatch } from 'react-redux'

import { authApi } from 'api/authApi'
import IconEyeClosed from 'assets/images/icons/eye-closed.png'
import IconEyeOpen from 'assets/images/icons/eye-open.png'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import { setAppToastMessage } from 'redux/reducers/app/appReducer'
import { translateType } from 'translations/types'
import { getValidation } from 'utils/client/signUpValidation'

import AuthTitle from '../../ui/authTitle'

import cls from './RecoverPassword.module.scss'

interface IProps {
  recoverHandler: () => void;
  t: translateType;
}

interface ISmsForm {
  phone: string;
}

interface IPasswordForm {
  password: string;
  smsCode: string;
}

const RecoverPassword: React.FC<IProps> = ({ recoverHandler, t }) => {
  const dispatch = useDispatch()

  const smsForm = useForm<ISmsForm>()
  const passwordForm = useForm<IPasswordForm>()

  const [smsFormSubmitting, setSmsFormSubmitting] = useState(false)
  const [isSmsFormSubmitted, setIsSmsFormSubmitted] = useState(false)
  const [smsFormError, setSmsFormError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isTimerStarted, setIsTimerStarted] = useState(false)
  const [passwordFormSubmitting, setPaswordFormSubmitting] = useState(false)
  const [passwordFormError, setPasswordFormError] = useState('')
  const timerTimeout = 60
  const [secondsLeft, setSecondsLeft] = useState(timerTimeout)
  const maxSmsCodeSentCount = 3
  const [smsCodeSentCount, setSmsCodeSentCount] = useState(0)
  const validation = getValidation(t)

  useEffect(() => {
    if (!isTimerStarted) {
      return
    }

    const resetPasswordTimerEnd = sessionStorage.getItem('resetPasswordTimerEnd')
    if (!resetPasswordTimerEnd) {
      return
    }

    const id = setInterval(() => {
      const secondsLeft = +resetPasswordTimerEnd - Math.floor(Date.now() / 1000)

      if (secondsLeft <= 0) {
        clearInterval(id)
        setIsTimerStarted(false)
        setSecondsLeft(timerTimeout)
        sessionStorage.removeItem('resetPasswordTimerEnd')
        return
      }

      setSecondsLeft(secondsLeft)
    }, 1000)

    return () => {
      clearInterval(id)
    }
  }, [isTimerStarted])

  const onSmsFormSubmit = async ({ phone }: ISmsForm) => {
    if (smsCodeSentCount >= maxSmsCodeSentCount) {
      return
    }

    setSmsFormError('')
    setSmsFormSubmitting(true)

    const res = await authApi.sendRestorePasswordSms(phone)

    if (res.status === 409) {
      setSmsFormError(t('homePage.logIn.attemptsLimitInOneDay'))
      setSmsFormSubmitting(false)
      return
    }
    if (res.status >= 400 && res.status !== 404) {
      setSmsFormError(t('homePage.logIn.commonError'))
      setSmsFormSubmitting(false)
      return
    }

    setIsSmsFormSubmitted(true)
    setSmsCodeSentCount(smsCodeSentCount + 1)
    sessionStorage.setItem('resetPasswordTimerEnd', `${Math.ceil((Date.now() / 1000)) + timerTimeout}`)
    setIsTimerStarted(true)
    setSmsFormSubmitting(false)
  }

  const onPasswordFormSubmit = async (data: IPasswordForm) => {
    setPaswordFormSubmitting(true)
    setPasswordFormError('')

    const res = await authApi.restorePassword({
      phone_number: smsForm.getValues('phone'),
      new_password: data.password,
      sms_code: data.smsCode
    })

    if (res.status === 403 || res.status === 404) {
      setPasswordFormError(t('homePage.logIn.wrongSmsCode'))
      setPaswordFormSubmitting(false)
      return
    }

    if (res.status >= 400) {
      setPasswordFormError(t('homePage.logIn.commonError'))
      setPaswordFormSubmitting(false)
      return
    }

    dispatch(
      setAppToastMessage({
        type: 'success',
        message: t('homePage.logIn.passwordChangeSuccess')
      })
    )
    recoverHandler()
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <AuthTitle password title={t('homePage.signUp.recovery')} />
      <Typography mb={3} textAlign="center">
        {t('homePage.logIn.smsWillBeSent')}
      </Typography>
      <form onSubmit={smsForm.handleSubmit(onSmsFormSubmit)}>
        <Box mb={2}>
          <Controller
            control={smsForm.control}
            name="phone"
            rules={{ ...validation.phone_number }}
            render={({ field: { ref, ...field } }) => (
              <PhoneInput
                inputProps={{ ref }}
                containerClass={cls.phoneInputContainer}
                inputClass={cls.input}
                buttonClass={cls.btnFlag}
                country={'ua'}
                specialLabel={t('homePage.logIn.registerPhone')}
                {...field}
                disabled={isSmsFormSubmitted}
              />
            )}
          />
          <FormErrorMessage errors={smsForm.formState.errors} name="phone" />
        </Box>
        <Box>
          <Button size="large" variant="contained" fullWidth type="submit"
            disabled={isTimerStarted || smsCodeSentCount >= maxSmsCodeSentCount || smsFormSubmitting}
          >
            {t('homePage.signUp.submitBtnSMS')} {isTimerStarted && smsCodeSentCount < maxSmsCodeSentCount && <>({secondsLeft})</>}
          </Button>
        </Box>
        {smsFormError &&
          <Typography color='error' mt={2} textAlign='center'>{smsFormError}</Typography>
        }
      </form>
      {isSmsFormSubmitted &&
        <form onSubmit={passwordForm.handleSubmit(onPasswordFormSubmit)}>
          <Box mb={2} mt={4}>
            <TextField
              className={cls.input}
              label={t('homePage.signUp.labelPassword')}
              {...passwordForm.register('password', {
                ...validation.password
              })}
              type={showPassword ? 'text' : 'password'}
              autoComplete="on"
              InputProps={{
                endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={toggleShowPassword}
                    edge="end"
                  >
                    {showPassword ? <img src={IconEyeOpen} alt="Show password"/> : <img src={IconEyeClosed} alt="Hide password"/>}
                  </IconButton>
                </InputAdornment>
              }}
            />
            <FormErrorMessage errors={passwordForm.formState.errors} name="password"/>
          </Box>
          <Box mb={2}>
            <TextField
              className={cls.input}
              label={t('homePage.signUp.labelCode')}
              {...passwordForm.register('smsCode', {
                ...validation.code
              })}
              type='text'
            />
            <FormErrorMessage errors={passwordForm.formState.errors} name="smsCode"/>
          </Box>
          <Box>
            <Button size="large" variant="contained" fullWidth type="submit" disabled={passwordFormSubmitting}>
              {t('homePage.logIn.resetPassword')}
            </Button>
          </Box>
          {passwordFormError &&
            <Typography color='error' mt={2} textAlign='center'>{passwordFormError}</Typography>
          }
        </form>
      }
      <Box sx={{ justifyContent: 'center', display: 'flex' }} mt={4} mb={4}>
        <Button onClick={recoverHandler}>
          {t('homePage.signUp.loginPage')}
        </Button>
      </Box>
    </>
  )
}

export default connect()(RecoverPassword)
