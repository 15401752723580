import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ConfirmModalSchema } from 'redux/reducers/common/confirmModal/confirmModalTypes'

const initialState: ConfirmModalSchema = {
  confirmAction: null,
  isActive: false,
  text: '',
  cancelButtonText: null,
  confirmButtonText: null
}

const confirmModalSlice = createSlice({
  name: 'confirmModal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{ confirmAction: Function, text: string, cancelButtonText?: string, confirmButtonText?:string }>) => {
      state.isActive = true
      state.confirmAction = action.payload.confirmAction
      state.text = action.payload.text
      state.cancelButtonText = action.payload.cancelButtonText ?? null
      state.confirmButtonText = action.payload.confirmButtonText ?? null
    },
    close: (state, action: PayloadAction<undefined>) => {
      state.isActive = false
      state.confirmAction = null
      state.text = ''
    },
    setValue: (state, action: PayloadAction<string>) => {

    }
  }
})

export const { actions: confirmModalActions } = confirmModalSlice
export const { reducer: confirmModalReducer } = confirmModalSlice
